import React from 'react';
import {Card, Tag, Popover} from 'antd';
import {connect} from "react-redux";
import {Colorscale} from 'react-colorscales';
import {withShell} from "../core/withShell";
import './MapArea.less';
import {initializeMap} from "../../Common/MapUtils";
import { calculateColorGradient } from '../../Common/CommonUtils';
import { HIGHEST_COLOR_CODE, LOWEST_COLOR_CODE } from '../constants/shellAppConstants';

class MapArea extends React.Component {
  componentDidMount() {
    initializeMap();
  }

  render() {
    const {
      $$shellStore,
    } = this.props;

    const minRWI = $$shellStore.get('minRWI');
    const maxRWI = $$shellStore.get('maxRWI');

    const steps = 5;

    const scaleArray = [];
    const colorsArray = [];

    if(maxRWI && minRWI){
      const rate = (maxRWI - minRWI) / steps;
      for (let index = 0; index <= steps; index++) {
        const scaleValue = minRWI + (rate * index);
        scaleArray.push(scaleValue.toFixed(2));
        const ratio = (rate * index) / (maxRWI - minRWI);
        colorsArray.push(calculateColorGradient(HIGHEST_COLOR_CODE, LOWEST_COLOR_CODE, ratio));
      }
    }

    return (
      <Card
        headStyle={ {backgroundColor: '#00B0A6'}}
        bordered={false}
        title={
          <div>
            {colorsArray?.length > 0 && scaleArray?.length > 0 && (
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{fontSize: 12}}>Lowest RWI</div>
              <div style={{fontSize: 12}}>Highest RWI</div>
            </div>)}
            {colorsArray?.length > 0 && (
            <Colorscale
              colorscale={colorsArray}
              onClick={() => {}}
              width={150}
            />)}
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
              {
                scaleArray?.length > 0 && scaleArray.map((item, index)=>(
                  <div key={item}>
                    <div style={{lineHeight: 0.9}}><strong>{item}</strong></div>
                  </div>
                ))
              }
            </div>
          </div>
        }
        className='map-wrapper'
        id='map-wrapper'
        style={{marginBottom: '16px'}}
        extra={this.props.mapStatus.length > 0 && <Tag color='blue'>
          {this.props.mapStatus}
        </Tag>}
      >
        <div id="map"/>
      </Card>
    )
  }
}

function select(state) {
  // $$ just indicates that it's Immutable.
  return {
    $$shellStore: state.$$shellStore,
  };
}

export default connect(select)(withShell(MapArea));
