import Immutable from 'immutable';
import {groupBy} from 'lodash';
import actionTypes from '../constants/shellAppConstants';

export const $$initialState = Immutable.fromJS({
  versionCode: 1,
  sideMenuCollapsed: false,
  navTheme: 'dark', // theme for nav menu
  primaryColor: '#1890FF', // primary color of ant design
  layout: 'sidemenu',
  fixedHeader: false,
  fixSiderbar: false,
  states: [],
  districtsAggregates:[],
  statesAggregates: [],
  stateCodeIndex: {},
  constituencies: [],
  constituencyStatesIndex: {},
  candidates: [],
  constituencyCandidateIndex: {},
  settings: {
    trackingMode: 'allStates',
    selectedStates: [],
    stateLevel: true,
    districtLevel: false,
  },
  minRWI: null,
  maxRWI: null,
  currentSelection: {
    id: null,
    type: null,
  },
  mapStatus: '',
  mapLoading: false,
  donateModalVisible: false,
});

export default function shellReducer($$state = $$initialState, action) {
  const { type, val } = action;
  switch (type) {
    case actionTypes.SIDEMENU_TOGGLE:
      return $$state.set('sideMenuCollapsed', val);
    case actionTypes.LOAD_STATES:
      let stateCodeIndex = {};
      Object
        .entries(groupBy(val.states, 'state_code'))
        .forEach((state) => {
          let obj = {};
          obj[state[0]] = state[1][0];
          stateCodeIndex = Object.assign(stateCodeIndex, obj)
        });
      return $$state
        .set('states', Immutable.fromJS(val.states))
        .set('stateCodeIndex', Immutable.fromJS(stateCodeIndex));
    case actionTypes.LOAD_DISTRICT_AGGREGATES: {
      return $$state.set('districtsAggregates', Immutable.fromJS(val))
    }
    case actionTypes.LOAD_STATE_AGGREGATES: {
      return $$state.set('statesAggregates', Immutable.fromJS(val))
    }
    case actionTypes.LOAD_PARTIES: {
      return $$state
        .set('parties', Immutable.fromJS(val));
    }
    case actionTypes.UPDATE_STATE_LEVEL_TRACKING: {
      const settings = $$state.get('settings').toJS();
      const settingsToWrite = Object.assign(settings, { stateLevel: val.stateLevel });
      val.setCookie('settings', settingsToWrite);
      return $$state.setIn(['settings', 'stateLevel'], val.stateLevel);
    }
    case actionTypes.UPDATE_TRACKING_MODE: {
      const settings = $$state.get('settings').toJS();
      const settingsToWrite = Object.assign(settings, { trackingMode: val.trackingMode });
      val.setCookie('settings', settingsToWrite);
      return $$state.setIn(['settings', 'trackingMode'], val.trackingMode);
    }
    case actionTypes.UPDATE_TRACKING_STATES: {
      const settings = $$state.get('settings').toJS();
      const settingsToWrite = Object.assign(settings, { selectedStates: val.states });
      val.setCookie('settings', settingsToWrite);
      return $$state.setIn(['settings', 'selectedStates'], Immutable.fromJS(val.states));
    }
    case actionTypes.UPDATE_MAP_STATUS:
      return $$state.set('mapStatus', val);
    case actionTypes.UPDATE_MAP_LOADING:
      return $$state.set('mapLoading', val);
    case actionTypes.RESTORE_SETTINGS:
      return $$state
        .setIn(['settings', 'selectedStates'], Immutable.fromJS(val.selectedStates))
        .setIn(['settings', 'trackingMode'], val.trackingMode)
        .setIn(['settings', 'stateLevel'], val.stateLevel);
    case actionTypes.UPDATE_CURRENT_SELECTION: {
      return $$state
        .setIn(['currentSelection', 'type'], val.type)
        .setIn(['currentSelection', 'id'], val.id);
    }
    case actionTypes.RESTORE_FAVORITES:
      return $$state.set('favorites', Immutable.fromJS(val));
    case actionTypes.UPDATE_MIN_RWI:
      return $$state.set('minRWI', Immutable.fromJS(val));
    case actionTypes.UPDATE_MAX_RWI:
      return $$state.set('maxRWI', Immutable.fromJS(val));
    case actionTypes.SHOW_DONATE_MODAL:
      return $$state.set('donateModalVisible', val);
    default:
      return $$state;
  }
}
