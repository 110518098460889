import {find, filter, compact, groupBy} from 'lodash';

export const calculateColorGradient = (inputColor1, inputColor2, percentage) => {
  // 0% means Color2
  // 100% means Color1
  const color1 = inputColor1.charAt(0) === '#' ? inputColor1.substring(1) : inputColor1;
  const color2 = inputColor1.charAt(0) === '#' ? inputColor2.substring(1) : inputColor2;
  const calculateHex = x => {
    if(x>=255){
      return 'ff';
    } else if(x<=0){
      return '00'
    }
    x = x.toString(16);
    return (x.length === 1) ? '0' + x : x;
  };
  const r = Math.ceil(
    parseInt(color1.substring(0,2), 16) * percentage
    + parseInt(color2.substring(0,2), 16) * (1-percentage)
  );
  const g = Math.ceil(
    parseInt(color1.substring(2,4), 16) * percentage
    + parseInt(color2.substring(2,4), 16) * (1-percentage)
  );
  const b = Math.ceil(
    parseInt(color1.substring(4,6), 16) * percentage
    + parseInt(color2.substring(4,6), 16) * (1-percentage)
  );
  return '#' + calculateHex(r) + calculateHex(g) + calculateHex(b);
};

export const isValidHexCode = (str) => {
  const regex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  if (str && typeof str === 'string' && str === '') {
    return false;
  } else {
    return regex.test(str);
  }
}

export const findKey = (element, fieldPathForAttr) => {
  const fieldPathArr = fieldPathForAttr.split('/');
  let keyValue = element;
  if (fieldPathArr && fieldPathArr.length > 0) {
    if (fieldPathArr.length === 1) {
      keyValue = element[fieldPathArr[0]];
    } else {
      for (let i = 0; i < fieldPathArr.length; i++) {
        const fieldKey = fieldPathArr[i];
        if (i === fieldPathArr.length - 1) {
          keyValue = keyValue[fieldKey];
        } else {
          const newObj = keyValue[fieldKey];
          keyValue = newObj;
        }
      }
    }
  }
  return keyValue;
};

export const generateColorCodes = (highestColorCode, lowestColorCode, data, fieldPathForAttr, dispatch, updateMinRWI, updateMaxRWI, errorColorCode = '#4285f4') => {
  let maxRWI = null;
  let minRWI = null;
  let isError = false;
  data.forEach(element => {
    try {
      const elementAvgRWI = parseFloat(findKey(element, fieldPathForAttr));
      if (!isNaN(elementAvgRWI) && (maxRWI === null || elementAvgRWI > maxRWI)) {
        maxRWI = elementAvgRWI;
      }
      if (!isNaN(elementAvgRWI) && (minRWI === null || elementAvgRWI < minRWI)) {
        minRWI = elementAvgRWI;
      }
    } catch (e) {
      isError = true;
    }
  });
  if(updateMinRWI){
    dispatch(updateMinRWI(minRWI)); 
  }
  if(updateMaxRWI){
    dispatch(updateMaxRWI(maxRWI)); 
  }
  data.forEach(element => {
    let colorCode = errorColorCode;
    try {
      const elementAvgRWI = parseFloat(findKey(element, fieldPathForAttr));
      if(!isNaN(elementAvgRWI)){
        const ratio = (elementAvgRWI - minRWI) / (maxRWI - minRWI);
        const hexCode = calculateColorGradient(highestColorCode, lowestColorCode, ratio);
        colorCode = isValidHexCode(hexCode) ? hexCode : errorColorCode;
      } else {
        colorCode = null;
      }
    } catch (e) {
      isError = true;
    }
    element['colorCode'] = isError ? errorColorCode : colorCode;
  });
  return data;
};

export const slugify = (text) => {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '_')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export function isMobileInNavigator() {
  const regex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
  return regex.test(navigator.userAgent.toLowerCase());
}

export function getStateFromCode(stateCodeIndex, stateCode) {
  return stateCodeIndex[stateCode];
}

export const copyToClipboard = (function initClipboardText() {
  const textarea = document.createElement('textarea');

  // Move it off screen.
  textarea.style.cssText = 'position: absolute; left: -99999em';

  // Set to readonly to prevent mobile devices opening a keyboard when
  // text is .select()'ed.
  textarea.setAttribute('readonly', true);

  document.body.appendChild(textarea);

  return function setClipboardText(text) {
    textarea.value = text;

    // Check if there is any content selected previously.
    const selected = document.getSelection().rangeCount > 0 ?
      document.getSelection().getRangeAt(0) : false;

    // iOS Safari blocks programmtic execCommand copying normally, without this hack.
    // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const editable = textarea.contentEditable;
      textarea.contentEditable = true;
      const range = document.createRange();
      range.selectNodeContents(textarea);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      textarea.setSelectionRange(0, 999999);
      textarea.contentEditable = editable;
    } else {
      textarea.select();
    }

    try {
      const result = document.execCommand('copy');

      // Restore previous selection.
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }

      return result;
    } catch (err) {
      return false;
    }
  };
})();


export const scrollToID = (id) => {
  document.getElementById(id).scrollIntoView({
    behavior: 'smooth'
  });
};
