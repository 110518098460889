import React, { PureComponent } from 'react';
import {Button} from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './GlobalHeader.less';
import {isMobileInNavigator} from "../../Common/CommonUtils";

export default class GlobalHeader extends PureComponent {
  componentWillUnmount() {
    if (typeof this.triggerResizeEvent.cancel === 'function') {
      this.triggerResizeEvent.cancel();
    }
  }
  /* eslint-disable*/
  //@Debounce(600)
  triggerResizeEvent() {
    // eslint-disable-line
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }
  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
    this.triggerResizeEvent();
  };

  render() {
    const { collapsed, isMobile, logo, donateModalToggle } = this.props;

    return (
      <div className='header'>
        {(isMobile || collapsed) && (
          <div className='logo'>
            <img src={logo} alt="logo" width="32" />
          </div>
        )}
        <span className='trigger' onClick={this.toggle}>
          {
            collapsed ?
              <MenuUnfoldOutlined/>
              : <MenuFoldOutlined/>
          }
        </span>
        <div className='social-icons'>
          {/*<Button shape="circle" className="fb" type="primary" onClick={onFacebookShare}>
            <FontAwesomeIcon icon={faFacebookF} />
          </Button>
          <Button shape="circle" className="wa" type="primary" onClick={onWhatsAppShare}>
            <FontAwesomeIcon icon={faWhatsapp} />
          </Button>
          <Button shape="circle" className="tw" type="primary" onClick={onTwitterShare}>
            <FontAwesomeIcon icon={faTwitter} />
          </Button>
          <Button shape="round" type="primary" onClick={() => donateModalToggle(true)}>
            Donate
          </Button>*/}
        </div>
      </div>
    );
  }
}
