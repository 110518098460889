import React from 'react';
import { ShellAppConsumer } from './ShellAppProvider';

export function withShell(ChildComponent) {
  return function ShellComponent(props) {
    return (
      <ShellAppConsumer>
        {
          ({
            ...rest
          }) => {
            return (
              <ChildComponent
                { ...props }
                { ...rest }
              />
            );
          }
        }
      </ShellAppConsumer>
    );
  };
}
