import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'SIDEMENU_TOGGLE',
  'LOAD_STATES',
  'UPDATE_TRACKING_MODE',
  'UPDATE_TRACKING_STATES',
  'UPDATE_MAP_STATUS',
  'UPDATE_MAP_LOADING',
  'RESTORE_SETTINGS',
  'UPDATE_STATE_LEVEL_TRACKING',
  'UPDATE_CURRENT_SELECTION',
  'RESTORE_FAVORITES',
  'SHOW_DONATE_MODAL',
  'LOAD_DISTRICT_AGGREGATES',
  'LOAD_STATE_AGGREGATES',
  'UPDATE_MIN_RWI',
  'UPDATE_MAX_RWI'
]);
export const HIGHEST_COLOR_CODE = '#03045e';
export const LOWEST_COLOR_CODE = '#caf0f8';
export default actionTypes;
