import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

export const ShellAppContext = React.createContext();

class ShellAppProvider extends Component {
  setCookie = (key, data) => {
    const { cookies } = this.props;
    cookies.set(key, JSON.stringify(data), { path: '/' });
  };

  deleteCookie = (key) => {
    const { cookies } = this.props;
    cookies.remove(key, { path: '/' });
  };

  getCookie = (key) => {
    const { cookies } = this.props;
    return cookies.get(key);
  };

  render() {
    const { children } = this.props;
    return (
      <ShellAppContext.Provider
        value={ {
          setCookie: this.setCookie,
          deleteCookie: this.deleteCookie,
          getCookie: this.getCookie,
        } }
      >
        { children }
      </ShellAppContext.Provider>
    );
  }
}

ShellAppProvider.propTypes = {
  children: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies),
};

export default withCookies(ShellAppProvider);

export const ShellAppConsumer = ShellAppContext.Consumer;
