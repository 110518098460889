import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root'
import './App.less';
import Shell from "./Shell/core/ShellApp";

class App extends Component {
  render() {
    return (
      <Shell/>
    );
  }
}

export default hot(App);
