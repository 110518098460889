import React, { PureComponent } from 'react';
import { Layout, Select, Radio, Button, Alert } from 'antd';
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import './SiderMenu.less';
import * as shellActionCreators from "../actions/shellActionCreators";
import {withShell} from "../core/withShell";
import {getStateFromCode} from "../../Common/CommonUtils";

const Option = Select.Option;
const { Sider } = Layout;
const RadioGroup = Radio.Group;
const { Content } = Layout;

class SiderMenu extends PureComponent {
  componentDidMount() {
    const {getCookie, onCollapse, isMobile} = this.props;
    const tourCompleted = getCookie('tourCompleted');
    if (tourCompleted && isMobile) {
      onCollapse(true);
    }
  }

  render() {
    const { logo, collapsed, onCollapse, fixSiderbar, isMobile, setCookie,  } = this.props;

    const {
      $$shellStore,
    } = this.props;

    const states = $$shellStore.get('states');
    const trackingMode = $$shellStore.getIn(['settings', 'trackingMode']);
    const stateLevel = $$shellStore.getIn(['settings', 'stateLevel']);
    const selectedStates = $$shellStore.getIn(['settings', 'selectedStates']);
    const stateCodeIndex = $$shellStore.get('stateCodeIndex').toJS();
    const mapLoading = $$shellStore.get('mapLoading');
    const actions = bindActionCreators(shellActionCreators, this.props.dispatch);

    const statesOptions = [];
    if (states) {
      states.toJS().forEach((state) => {
        statesOptions.push(<Option key={state.state_code} value={state.state_code} text={state.name}>{state.name}</Option>);
      })
    }

    const handleStatesSelection = (selectedStates) => {
      actions.updateSelectedStates(selectedStates, setCookie)
    };

    const handleTrackingMode = (event) => {
      actions.updatetrackingMode(event.target.value, setCookie)
    };

    const handleStateLevel = (stateLevel) => {
      actions.updateStateLevel(stateLevel, setCookie)
    };

    const siderClassName = classNames('sider', {
      'fixSiderBar': fixSiderbar,
    });

    const radioStyle = {
      display: 'block',
      height: '30px',
      // lineHeight: '30px',
    };

    let message = 'Load ';

    if (stateLevel) {
      message = message + 'state level maps '
    } else {
      message = message + 'district level maps '
    }

    if(trackingMode === 'allStates' || trackingMode === 'allDistricts') {
      message = message + 'of whole India.'
    } else {
      let stateNames = [];
      if (selectedStates) {
        selectedStates.toJS().forEach(stateCode => {
          stateNames.push(getStateFromCode(stateCodeIndex, stateCode).name)
        })
      }
      message = message + 'of ' + stateNames.join(', ')
    }

    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        onCollapse={collapse => {
          if (!isMobile) {
            onCollapse(collapse);
          }
        }}
        width={256}
        collapsedWidth={0}
        theme='light'
        className={siderClassName}
      >
        <div className='side-logo' id="logo">
          <img src={logo} alt="logo" />
          <h1>Together for her SEC</h1>
        </div>

        <Content style={{padding: 16}}>
          <div id='tracking-mode'>
            <div><strong>Select maps to load</strong></div>
            <div style={{marginLeft: 10}}>
              <div style={{marginTop: 8}}>
                <div><strong>States of</strong></div>
                <RadioGroup
                  onChange={(e) => {
                      handleTrackingMode(e);
                      handleStateLevel(true);
                    }
                  }
                  value={trackingMode} style={{marginLeft: 10}}
                >
                  <Radio style={radioStyle} value={'allStates'}>Whole India</Radio>
                </RadioGroup>
              </div>
              <div style={{marginTop: 8}}>
              <div><strong>Districts of</strong></div>
              <RadioGroup
                onChange={(e) => {
                  handleTrackingMode(e);
                  handleStateLevel(false);
                }}
                value={trackingMode}
                style={{marginLeft: 10}}
              >
                <Radio style={radioStyle} value={'allDistricts'}>Whole India</Radio>
                <Radio style={radioStyle} value={'stateWiseDistricts'}>Selected state(s)</Radio>
              </RadioGroup>
              {
                trackingMode === 'stateWiseDistricts' &&
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    mode="multiple"
                    style={{ width: '100%', marginTop: 8, marginLeft: 8 }}
                    placeholder="Please select state(s) to track"
                    onChange={handleStatesSelection}
                    value={selectedStates ? selectedStates.toJS() : []}
                  >
                    {statesOptions}
                  </Select>
              }
              </div>
            </div>
          </div>

          <div>
            <Button
              type="primary"
              loading={mapLoading}
              onClick={() => actions.loadData()}
              style={{marginTop: '16px'}}
              disabled={trackingMode === 'statewise' && selectedStates && selectedStates.toJS().length === 0}
            >
              {
                mapLoading ? 'Loading' : 'Load'
              }
            </Button>
          </div>

          <div style={{marginTop: '16px'}}>
            <Alert message={message} type="warning" />
          </div>

        </Content>
      </Sider>
    );
  }
}

function select(state) {
  // $$ just indicates that it's Immutable.
  return {
    $$shellStore: state.$$shellStore,
  };
}

export default connect(select)(withShell(SiderMenu));
