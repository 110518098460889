import React from 'react';
import { Row, Col, Layout, BackTop } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tour from 'reactour'
import logo from '../assets/logo.jpg';
import Header from './Header';
import Footer from "./Footer";
import SiderMenu from './SideDrawer';
import './BasicLayout.less';
import * as shellActionCreators from '../actions/shellActionCreators';
import GridContent from "../../Common/GridContent";
import MapArea from "../components/MapArea";
import {withShell} from "../core/withShell";
import RailsUtils from "../../Common/RailsUtils";
import CurrentSelection from "../components/CurrentSelection";
import {scrollToID} from "../../Common/CommonUtils";

const { Content } = Layout;

class BasicLayout extends React.Component {
  constructor(props) {
    super(props);
    const {getCookie} = props;
    const tourCompleted = getCookie('tourCompleted');
    this.state = {
      tourCompleted: !!tourCompleted
    }
  }

  componentDidMount() {
    const railsUtils = new RailsUtils();
    const actions = bindActionCreators(shellActionCreators, this.props.dispatch);
    const { getCookie } = this.props;
    const allPromises = (updating = false) => {
      // get states
      const statesPromise = new Promise((resolve) => {
        railsUtils
          .getStates()
          .then(states => {
            actions.loadStates(states);
            // restore settings
            const settings = getCookie('settings');
            if (settings) {
              actions.restoreSettings(settings);
            }
            resolve();
          });
      });
      return [statesPromise];
    }

    Promise.all(allPromises()).then(() => {
      setTimeout(() => {
        actions.loadData();
      }, 3000);
    });
  }

  getLayoutStyle = () => {
    const { isMobile } = this.props;
    const {
      $$shellStore,
    } = this.props;

    const fixSiderbar = $$shellStore.get('fixSiderbar');
    const layout = $$shellStore.get('layout');
    const collapsed = $$shellStore.get('sideMenuCollapsed');
    if (fixSiderbar && layout !== 'topmenu' && !isMobile) {
      return {
        paddingLeft: collapsed ? '0px' : '256px',
      };
    }
    return null;
  };



  render() {
    const {
      isMobile, setCookie
    } = this.props;

    const onCompleteTour = () => {
      setCookie('tourCompleted', true);
      this.setState({tourCompleted: true});
    };

    const {
      $$shellStore,
    } = this.props;

    const navTheme = $$shellStore.get('navTheme');
    const fixedHeader = $$shellStore.get('fixedHeader');
    const fixSiderbar = $$shellStore.get('fixSiderbar');
    const sideMenuCollapsed = $$shellStore.get('sideMenuCollapsed');
    const mapStatus = $$shellStore.get('mapStatus');

    const actions = bindActionCreators(shellActionCreators, this.props.dispatch);

    const handleMenuCollapse = collapsed => {
      actions.sideMenuToggle(collapsed)
    };

    const steps = [
      {
        selector: '#tracking-mode',
        content: 'Here you can choose to load either whole India map or select states(s) of your interest.',
      },
      {
        selector: '#map-wrapper',
        content: 'Each map area will have a colour as per the average RWI value of the users.',
      },
      {
        selector: '#current-selection',
        content: 'Click on an area on the map to see the details about the administrative block.',
      },
  ];

    const contentStyle = !fixedHeader ? { paddingTop: 0 } : {};
    const layout = (
      <Layout>
        <BackTop visibilityHeight={250}/>
        <Tour
          steps={steps}
          isOpen={!this.state.tourCompleted}
          onRequestClose={() => {onCompleteTour()}}
          getCurrentStep={curr => {
            if (curr === 1 || curr === 0) {
              scrollToID('header');
              handleMenuCollapse(false);
            }
            if (!(curr === 1 || curr === 0) && isMobile) {
              handleMenuCollapse(true);
            }
            if (curr === 4 && isMobile) {
              scrollToID('favorites');
            }
          }}
        />
        <SiderMenu
          logo={logo}
          theme={navTheme}
          onCollapse={handleMenuCollapse}
          isMobile={isMobile}
          collapsed={sideMenuCollapsed}
          fixSiderbar={fixSiderbar}
        />
        <Layout
          style={{
            ...this.getLayoutStyle(),
            minHeight: '100vh',
          }}
        >
          <Header
            handleMenuCollapse={handleMenuCollapse}
            logo={logo}
            isMobile={isMobile}
            {...this.props}
          />
          <Content className='content' style={contentStyle}>
            <GridContent>
              <Row gutter={24} type="flex">
                <Col xl={15} lg={15} md={24} sm={24} xs={24}>
                  <MapArea mapStatus={mapStatus}/>
                </Col>
                <Col xl={9} lg={9} md={24} sm={24} xs={24} className='right-side'>
                  <CurrentSelection/>
                </Col>
              </Row>
            </GridContent>
          </Content>
          <Footer />
        </Layout>
      </Layout>
    );
    return (
      <React.Fragment>
        { layout }
      </React.Fragment>
    );
  }
}

function select(state) {
  // $$ just indicates that it's Immutable.
  return {
    $$shellStore: state.$$shellStore,
  };
}

export default connect(select)(withShell(BasicLayout));
