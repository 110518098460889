import AxiosCache from "./AxiosCache";
import axios from 'axios';
const axiosWithCache = new AxiosCache();

class RailsUtils {

  static async railsRequestWithCache(url) {
    return await axiosWithCache.getRequest(url);
  }

  static async railsRequest(url) {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  }

  async getStates() {
    return await RailsUtils.railsRequestWithCache(`/lists/states.json`);
  }
}

export default RailsUtils;
