import localforage from 'localforage'
import { setup } from 'axios-cache-adapter'

class AxiosCache {
  constructor() {
    const store = localforage.createInstance({
      driver: [
        localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
      ],
      name: 'sec-cache'
    })

    this.axiosWithCache = setup({
      cache: {
        maxAge: 24 * 60 * 60 * 1000,
        store
      }
    });
  }

  async getRequest(url) {
    const response = await this.axiosWithCache.get(url, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  }
}

export default AxiosCache;
