import React, { Component } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GlobalHeader from './GlobalHeader';
import * as shellActionCreators from '../actions/shellActionCreators';
import './Header.less';

const { Header } = Layout;

class HeaderView extends Component {
  getHeadWidth = () => {
    const { isMobile } = this.props;
    const {
      $$shellStore,
    } = this.props;
    const collapsed = $$shellStore.get('sideMenuCollapsed');
    const fixedHeader = $$shellStore.get('fixedHeader');
    if (isMobile || !fixedHeader) {
      return '100%';
    }
    return collapsed ? '100%' : 'calc(100% - 256px)';
  };

  render() {
    const { handleMenuCollapse } = this.props;

    const {
      $$shellStore,
    } = this.props;

    const fixedHeader = $$shellStore.get('fixedHeader');
    const collapsed = $$shellStore.get('sideMenuCollapsed');
    const actions = bindActionCreators(shellActionCreators, this.props.dispatch);

    const width = this.getHeadWidth();
    return (
      <Header
        style={{ padding: 0, width, zIndex: 2 }}
        className={fixedHeader ? 'fixedHeader' : ''}
        id='header'
      >
        <GlobalHeader
          onCollapse={handleMenuCollapse}
          collapsed={collapsed}
          donateModalToggle={actions.donateModalToggle}
          {...this.props}
        />
      </Header>
    );
  }
}

function select(state) {
  // $$ just indicates that it's Immutable.
  return {
    $$shellStore: state.$$shellStore,
  };
}

export default connect(select)(HeaderView);