import React from 'react';
import { Layout } from 'antd';
import './Footer.less';

const { Footer } = Layout;

const FooterView = () => (
  <Footer style={{ padding: 0 }}>
    <footer className='globalFooter'>
      <div className='links'>
      </div>
      <div className="copyright">
      </div>
    </footer>
  </Footer>
);
export default FooterView;
