import React from 'react';
import { Provider } from 'react-redux';
import Media from "react-media";
import ReactGA from 'react-ga';
import { Cookies, CookiesProvider } from 'react-cookie';
import ShellAppProvider from './ShellAppProvider';
import createStore from '../store/shellStore';
import BasicLayout from "../containers/BasicLayout";

class Shell extends React.Component {

  componentDidMount() {
    if (window.location.host.includes('election')) {
      ReactGA.initialize('UA-140455203-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    const store = createStore(this.props);
    const cookies = new Cookies();
    return(
      <Provider store={ store }>
        <CookiesProvider cookies={ cookies }>
          <ShellAppProvider { ...this.props }>
            <Media query="(max-width: 599px)">
              {isMobile => <BasicLayout isMobile={isMobile}/>}
            </Media>
          </ShellAppProvider>
        </CookiesProvider>
      </Provider>
    )
  }
}

export default Shell;
