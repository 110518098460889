import actionTypes, { HIGHEST_COLOR_CODE, LOWEST_COLOR_CODE } from '../constants/shellAppConstants';
import AxiosCache from "../../Common/AxiosCache";
import axios from "axios";
import {calculateColorGradient, generateColorCodes, getWinningPartyForStateColor, getWinningPartyInConstituency, slugify} from "../../Common/CommonUtils";
import { zoomMap } from "../../Common/MapUtils";

const axioWithCache = new AxiosCache();

export function sideMenuToggle(collapsed) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SIDEMENU_TOGGLE, val: collapsed });
  };
}

export function donateModalToggle(visible) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SHOW_DONATE_MODAL, val: visible });
  };
}

export function loadStates(states) {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_STATES, val: states });
  };
}

export function loadParties(parties) {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_PARTIES, val: parties });
  };
}

export function loadCandidates(candidates) {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_CANDIDATES, val: candidates });
  };
}

export function loadConstituencies(constituencies) {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_CONSTITUENCIES, val: constituencies });
  };
}

export function restoreSettings(settings) {
  return (dispatch) => {
    const trackingMode = settings.trackingMode;
    const selectedStates = settings.selectedStates;
    const stateLevel = settings.stateLevel;
    dispatch({ type: actionTypes.RESTORE_SETTINGS, val: { trackingMode, selectedStates, stateLevel } });
  };
}

export function restoreFavorites(favorites) {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESTORE_FAVORITES, val: favorites });
  };
}

export function updateMinRWI(minRWI) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_MIN_RWI, val: minRWI });
  };
}

export function updateMaxRWI(maxRWI) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_MAX_RWI, val: maxRWI });
  };
}

export function updateFavorites(constituencies, candidates, parties, setCookie) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_FAVORITES, val: { constituencies, candidates, parties, setCookie } });
  };
}

export function updateStateLevel(stateLevel, setCookie) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_STATE_LEVEL_TRACKING, val: { stateLevel, setCookie } });
  };
}

export function updatetrackingMode(trackingMode, setCookie) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_TRACKING_MODE, val: { trackingMode, setCookie } });
  };
}

export function updateSelectedStates(states, setCookie) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_TRACKING_STATES, val: { states, setCookie } });
  };
}

export const updateMapStatus = (value) => {
  return (dispatch) => {
    dispatch({type: actionTypes.UPDATE_MAP_STATUS, val: value });
  }
}

export const updateMapLoading = (value) => {
  return (dispatch) => {
    dispatch({type: actionTypes.UPDATE_MAP_LOADING, val: value});
  }
};

export const updateCurrentSelection = (id, type) => {
  return (dispatch) => {
    dispatch({type: actionTypes.UPDATE_CURRENT_SELECTION, val: { id, type }});
  }
}

export const updateAggregates = (type, data) => {
  return (dispatch) => {
    if (type === 'district') {
      dispatch({type: actionTypes.LOAD_DISTRICT_AGGREGATES, val: data});
    } else {
      dispatch({type: actionTypes.LOAD_STATE_AGGREGATES, val: data});
    }
  }
}

async function fetchGeoJSON(url) {
  return await axioWithCache.getRequest(url)
}

async function fetchDataJSON(url) {
  const response = await axios.get(url)
  return response.data;
}

export async function fetchStateJson(stateName, updating) {
  if (updating) {
    return;
  }
  return await fetchGeoJSON(`/geojsons/states/${stateName}.geojson`)
}

export async function fetchDistrictJson(districtName, updating) {
  if (updating) {
    return;
  }
  return await fetchGeoJSON(`/geojsons/districts/${districtName}.geojson`)
}

export async function fetchStatesAggregatesJson(stateName, updating) {
  if (updating) {
    return;
  }
  return await fetchDataJSON(`/aggregates/states/${stateName}.json`)
}

export async function fetchDistrictsAggregatesJson(districtName, updating) {
  if (updating) {
    return;
  }
  return await fetchDataJSON(`/aggregates/districts/${districtName}.json`)
}

export async function fetchDistrictsAggregatesJsonForSelectedStates(selectedStates, updating) {
  if (updating) {
    return;
  }
  let data = [];
  return new Promise((resolve, reject) => {
    selectedStates.forEach((element, index) => {
      fetchDistrictsAggregatesJson(element, updating)
      .then(districtData=>{
        data = [...data,...districtData.districts];
        if(index === selectedStates.size -1) {
          resolve(data);
        }
      });
    });
  });
}

function loadGeoJsonString(geojson, updating = false) {
  if (!updating) {
    window.map.data.addGeoJson(geojson);
    zoomMap(window.map);
  }
}

export const loadData = (updating = false) => {
  return (dispatch, getState) => {
    dispatch(updateCurrentSelection(null, null));
    dispatch(updateMapLoading(true));
    const globalstate = getState();
    const $$shellStore = globalstate['$$shellStore'];
    const trackingMode = $$shellStore.getIn(['settings', 'trackingMode']);
    const stateLevel = $$shellStore.getIn(['settings', 'stateLevel']);
    const selectedStates = $$shellStore.getIn(['settings', 'selectedStates']);
    const stateCodeIndex = $$shellStore.get('stateCodeIndex').toJS();
    if (!updating) {
      dispatch(updateMapStatus('Resetting map...'));
      window.map.data.forEach(function(feature) {
        window.map.data.remove(feature);
      });
    } else {
      dispatch(updateMapStatus('Updating map...'));
    }
    if (trackingMode === 'allStates') {
      if (!updating) {
        dispatch(updateMapStatus('Fetching India geography...'));
      }
      fetchStateJson('all', updating)
        .then(data => loadGeoJsonString(data, updating))
        .then(() => processStats(dispatch, getState))
        .then(() => applyColors())
        .then(() => dispatch(updateMapStatus('')))
        .then(() => dispatch(updateMapLoading(false)))
        .catch(e => console.error(e));
    } else if (trackingMode === 'allDistricts') {
      if (!updating) {
        dispatch(updateMapStatus('Fetching India geography...'));
      }
      fetchDistrictJson('all', updating)
        .then(data => loadGeoJsonString(data, updating))
        .then(() => processStats(dispatch, getState))
        .then(() => applyColors())
        .then(() => dispatch(updateMapStatus('')))
        .then(() => dispatch(updateMapLoading(false)))
        .catch(e => console.error(e));
    } else if (trackingMode === 'stateWiseDistricts') {
      selectedStates.forEach(element => {
        fetchDistrictJson(element, updating)
        .then(data => loadGeoJsonString(data, updating))
        .then(() => processStats(dispatch, getState))
        .then(() => applyColors())
        .then(() => dispatch(updateMapStatus('')))
        .then(() => dispatch(updateMapLoading(false)))
        .catch(e => console.error(e));
      });
    }
  };
};


async function processStats(dispatch, getState) {
  const globalstate = getState();
  const $$shellStore = globalstate['$$shellStore'];
  const stateLevel = $$shellStore.getIn(['settings', 'stateLevel']);
  const trackingMode = $$shellStore.getIn(['settings', 'trackingMode']);
  const selectedStates = $$shellStore.getIn(['settings', 'selectedStates']);
  let promise = new Promise((resolve, reject) => {
    setTimeout(() => {
      dispatch(updateMapStatus('Calculating statistics...'))
      let fetchSlug = trackingMode === 'allStates' ? 'all_states' : 'all_districts';
      let fetchAggregatesJson = trackingMode === 'allStates' ? fetchStatesAggregatesJson : fetchDistrictsAggregatesJson;
      if(trackingMode === 'stateWiseDistricts') {
        fetchAggregatesJson = fetchDistrictsAggregatesJsonForSelectedStates;
        fetchSlug = selectedStates;
      }
        fetchAggregatesJson(fetchSlug)
        .then(data => {
          if (trackingMode === 'allDistricts' || trackingMode === 'stateWiseDistricts') {
            dispatch(updateAggregates('district', data))
          } else {
            dispatch(updateAggregates('state', data))
          }
          const fieldPath = trackingMode === 'stateWiseDistricts' ? 'aggregation/average_rwi' : 'aggregates/average_rwi';
          const aggregatesJSONWithColorCodes = generateColorCodes(HIGHEST_COLOR_CODE, LOWEST_COLOR_CODE, data, fieldPath, dispatch, updateMinRWI, updateMaxRWI);
          window.map.data.forEach((feature) => {
            let color;
            if (trackingMode === 'allStates') {
              const stateCode = feature.getProperty('state_code');
              const obj = aggregatesJSONWithColorCodes.find(ele => ele.code === stateCode);
              color = obj ? obj.colorCode : null;
            } else if (trackingMode === 'allDistricts' || trackingMode === 'stateWiseDistricts') {
              const districtCode = feature.getProperty('district_code');
              const obj = aggregatesJSONWithColorCodes.find(ele => ele.code === districtCode);
              color = obj ? obj.colorCode : null;
            }
            if(color){
              feature.setProperty('color', color);
              feature.setProperty('strokeColor', color + '80');
            } else {
              feature.setProperty('color', '#ffffff00');
              feature.setProperty('strokeColor', calculateColorGradient(HIGHEST_COLOR_CODE, LOWEST_COLOR_CODE, 0.5));
            }
          });
        });

      window.map.data.addListener('click', function(event) {
        if (event.feature.getProperty('district_code')) {
          dispatch(updateCurrentSelection(event.feature.getProperty('district_code'), 'district'))
        } else {
          dispatch(updateCurrentSelection(event.feature.getProperty('state_code'), 'state'))
        }
      });

      dispatch(updateMapStatus('Processed stats...'));
      resolve('Processed stats...')
    }, 1000)
  });
  let result = await promise;
}

function applyColors() {
  window.map.data.setStyle((feature) => {
    let color = feature.getProperty('color');
    let strokeColor = feature.getProperty('strokeColor');
    return ({
      fillColor: color,
      strokeColor: strokeColor,
      fillOpacity: 0.95,
      strokeWeight: 0.5
    });
  });
}
