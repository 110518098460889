import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Alert, Card, Button } from 'antd';
import './CurrentSelection.less';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import * as shellActionCreators from "../actions/shellActionCreators";
import SelectedBoundaryDescription from "./SelectedBoundaryDescription";
import { CloseOutlined } from '@ant-design/icons'

class CurrentSelection extends React.Component {
  shouldComponentUpdate(nextProps) {
    const $$shellStorePrev = this.props.$$shellStore;
    const currentSelectionPrev = $$shellStorePrev.get('currentSelection').toJS();
    const minRWIPrev = $$shellStorePrev.get('minRWI');
    const maxRWIPrev = $$shellStorePrev.get('maxRWI');

    const $$shellStoreNext = nextProps.$$shellStore;
    const currentSelectionNext = $$shellStoreNext.get('currentSelection').toJS();
    const minRWINext = $$shellStoreNext.get('minRWI');
    const maxRWINext = $$shellStoreNext.get('maxRWI');

    if (minRWIPrev !== minRWINext
      || maxRWIPrev !== maxRWINext
      || currentSelectionPrev?.id !== currentSelectionNext?.id) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const { $$shellStore } = this.props;
    const trackingMode = $$shellStore.getIn(['settings', 'trackingMode']);
    const currentSelection = $$shellStore.get('currentSelection').toJS();
    const statesAggregates = $$shellStore.get('statesAggregates').toJS();
    const districtsAggregates = $$shellStore.get('districtsAggregates').toJS();
    const minRWI = $$shellStore.get('minRWI');
    const maxRWI = $$shellStore.get('maxRWI');
    const actions = bindActionCreators(shellActionCreators, this.props.dispatch);

    let data = null;

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

    const renderCustomizedLabel = ({
      value
    }) => {
      if(!data) return '';
      const totalNumberOfUsers = data.map(item => item.value).reduce((prev, curr) => prev + curr, 0);
      const percentage = (value * 100 / totalNumberOfUsers).toFixed(0);
      return `${percentage}%`;
    };

    const onResetCurrentSelection = () => {
      actions.updateCurrentSelection(null, null);
    }

    let selectionDiv = '';
    let title = ''
    const dataArray = [
      {name: "Higher", value: 0},
      // {name: "High", value: 0},
      {name: "Middle", value: 0},
      // {name: "Low", value: 0},
      {name: "Lower", value: 0},
    ];

    if (currentSelection.id) {
      if (currentSelection.type === "state") {
        const selectedState = statesAggregates.find(e => e.code === currentSelection.id);
        if (selectedState) {
          const user_category = selectedState.user_category;
          dataArray[0].value += (user_category.highest || 0) + (user_category.high || 0);
          dataArray[1].value += user_category.moderate || 0;
          dataArray[2].value += (user_category.low || 0) + (user_category.lowest || 0);
          data = dataArray;
          title = selectedState.name
          selectionDiv = <div>
            <SelectedBoundaryDescription
              totalUsers={ selectedState.aggregates?.total_users || 'NA' }
              averageRWI={ selectedState.aggregates?.average_rwi || 'NA'}
              highestRWI={ selectedState.aggregates?.highest_rwi || 'NA' }
              lowestRWI={ selectedState.aggregates?.lowest_rwi || 'NA' }
            />
          </div>
        } else {
          selectionDiv = <div>
            <Alert
              message="Error"
              description="Missing data / state not mapped to boundaries."
              type="error"
              showIcon
            />
          </div>
        }
      } else {
        const selectedDistrict = districtsAggregates.find(e => e.code === currentSelection.id);
        if (selectedDistrict) {
          const user_category = selectedDistrict.user_category;
          if (user_category) {
            dataArray[0].value = (user_category.highest || 0) + (user_category.high || 0);
            dataArray[1].value = user_category.moderate || 0;
            dataArray[2].value = (user_category.low || 0) + (user_category.lowest || 0);
            data = dataArray;
          }
          title = selectedDistrict.name
          selectionDiv = <div>
            <SelectedBoundaryDescription
              totalUsers={ (selectedDistrict.aggregation || selectedDistrict.aggregates)?.total_users || 'NA' }
              averageRWI={ (selectedDistrict.aggregation || selectedDistrict.aggregates)?.average_rwi || 'NA' }
              highestRWI={ (selectedDistrict.aggregation || selectedDistrict.aggregates)?.highest_rwi || 'NA' }
              lowestRWI={ (selectedDistrict.aggregation || selectedDistrict.aggregates)?.lowest_rwi || 'NA' }
            />
          </div>
        } else {
          selectionDiv = <div>
            <Alert
              message="Error"
              description="Missing data / district not mapped to boundaries."
              type="error"
              showIcon
            />
          </div>
        }
      }
    } else {
      let averageRWISum = 0, totalUsers = 0, totalStates = 0;
      if(statesAggregates.length > 0 && trackingMode === 'allStates'){
        statesAggregates.forEach(element => {
          const user_category = element.user_category;
          const aggregates = element.aggregates;
          if (user_category) {
            dataArray[0].value += (user_category.highest || 0) + (user_category.high || 0);
            dataArray[1].value += user_category.moderate || 0;
            dataArray[2].value += (user_category.low || 0) + (user_category.lowest || 0);
          }
          if(aggregates && aggregates.average_rwi !== null && aggregates.average_rwi !== undefined){
            averageRWISum += (aggregates.average_rwi || 0);
            totalUsers += (aggregates.total_users || 0);
            totalStates++;
          }
        });
      } else if (districtsAggregates.length > 0) {
        districtsAggregates.forEach(element => {
          const user_category = element.user_category;
          const aggregates = element.aggregates ?? element.aggregation
          if (user_category) {
            dataArray[0].value += (user_category.highest || 0) + (user_category.high || 0);
            dataArray[1].value += user_category.moderate || 0;
            dataArray[2].value += (user_category.low || 0) + (user_category.lowest || 0);
          }
          if(aggregates && aggregates.average_rwi !== null && aggregates.average_rwi !== undefined){
            averageRWISum += (aggregates.average_rwi || 0);
            totalUsers += (aggregates.total_users || 0);
            totalStates++;
          }
        });
      }
      if(totalUsers && totalStates){
        selectionDiv = <div>
          <SelectedBoundaryDescription
            totalUsers={ totalUsers || 'NA' }
            averageRWI={ (averageRWISum/totalStates)?.toFixed(2) || 'NA' }
            highestRWI={ maxRWI?.toFixed(2) || 'NA' }
            lowestRWI={ minRWI?.toFixed(2) || 'NA' }
          />
        </div>
      }
      data = dataArray;
    }

    let tableTitle = 'Select area on map';

    if(currentSelection.id){
      tableTitle = title;
    } else if (trackingMode === 'allStates') {
      tableTitle = 'All States'
    } else if (trackingMode === 'allDistricts') {
      tableTitle = 'All Districts'
    } else if (trackingMode === 'stateWiseDistricts') {
      tableTitle = 'All Districts of Selected States'
    }

    return (
      <div>
        <Card
          bordered={false}
          title={ tableTitle }
          className='current-selection'
          id='current-selection'
          extra={
            currentSelection.id &&
            <Button
              type="primary"
              shape="circle"
              icon={ <CloseOutlined/> }
              size='small'
              onClick={onResetCurrentSelection}
            />
          }
        >
          { selectionDiv }
        </Card>
        <Card
          bordered={false}
          title="SEC distribution"
          className='current-selection'
          id='sec-distribution'
          extra={
            currentSelection.id &&
            <Button
              type="primary"
              shape="circle"
              icon={ <CloseOutlined/> }
              size='small'
              onClick={onResetCurrentSelection}
            />
          }
        >
          {
            Array.isArray(data) && data.map(item => item.value).reduce((prev, curr) => prev + curr, 0) > 0
            && (
              <div style={{ display: 'flex'}}>
                <PieChart width={230} height={250}>
                  <Pie
                    key={Math.random()}
                    data={data}
                    animationDuration={1000}
                    labelLine={true}
                    label={renderCustomizedLabel}
                    outerRadius={65}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {
                      data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))
                    }
                  </Pie>
                  <Tooltip
                  offset={25}
                  content={({ active, payload }) => {
                    if(active && payload?.length > 0 ){
                      return <div style={{
                        background: '#Efe6dd', paddingInline: 5, color: 'black', borderWidth: 10, borderColor: 'black',
                        'border':'2px solid black'
                      }}>{ payload[0].name } Class<br/>{ payload[0].value } users</div>
                    }
                    return null
                }}
                  />
                </PieChart>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40}}>
                  <strong style={{ }}>Type of Class</strong>
                  {
                    data.map((item, index)=>(
                      <div style={{display: 'flex', alignItems: 'center'}} key={COLORS[index]}>
                        <div style={{
                          display: 'inline-block',
                          width: '18px',
                          height: '18px',
                          background: COLORS[index],
                        }} />
                        <div style={{
                          display: 'flex', flexDirection: 'column', marginLeft: 8, marginBlock: 10,
                        }}>
                        <span style={{ }}>
                          {item.name}
                        </span>
                        <span style={{lineHeight: 1, fontSize: 10 }}>
                          {item.value} Users
                        </span>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          }
        </Card>
      </div>
    )
  }
}

function select(state) {
  // $$ just indicates that it's Immutable.
  return {
    $$shellStore: state.$$shellStore,
  };
}

export default connect(select)(CurrentSelection);
