import React from 'react';
import { Descriptions } from 'antd';

class SelectedBoundaryDescription extends React.Component {
  render() {

    const { averageRWI, totalUsers, highestRWI, lowestRWI } = this.props;
    return (
      <div>
        <Descriptions bordered>
          <Descriptions.Item label="Average RWI" span={4}>{ averageRWI }</Descriptions.Item>
          <Descriptions.Item label="Highest RWI" span={4}>{ highestRWI }</Descriptions.Item>
          <Descriptions.Item label="Lowest RWI" span={4}>{ lowestRWI }</Descriptions.Item>
          <Descriptions.Item label="Total users" span={4}>{ totalUsers }</Descriptions.Item>
        </Descriptions>
      </div>
    )
  }
}

export default SelectedBoundaryDescription;
